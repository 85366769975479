import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Pagination, PageHero, Seo, Accordion } from "../components";

const BlogTemplate = ({ data, pageContext: { numPages, currentPage } }) => {
  const posts = data.allContentfulBlog.nodes;
  return (
    <>
      <Seo
        title="Blog"
        description="Short posts that cover from a prayer topic to an misconception clearification to a inspirational talk."
      />
      <PageHero
        img={
          <StaticImage
            src={"../images/scott-webb-v5fHBPN3liU-unsplash.jpg"}
            alt="Ryerson art building taken from the other side of the pond."
            placeholder="blurred"
            layout="constrained"
            width={3840}
            height={2160}
            loading="eager"
            className="hero-img"
          />
        }
        title="Blogs"
        text="Learn more by reading through our recent reflections, testimonies, and information from our members."
      />

      <div className="page-main">
        {posts.map(({ id, content: { content }, title, createdAt }) => {
          return <Accordion key={id} data={{ content, title, createdAt }} />;
        })}
      </div>

      <Pagination {...{ target: "blog", numPages, currentPage }} />
    </>
  );
};

export const query = graphql`
  query BlogQuery($skip: Int!, $limit: Int!) {
    allContentfulBlog(
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: createdAt }
    ) {
      nodes {
        authors
        id
        content {
          content
        }
        title
        createdAt(formatString: "YYYY/MM/DD")
      }
    }
  }
`;

export default BlogTemplate;
